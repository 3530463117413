<template>
  <div>
  <b-card
    class="warehousecharge-edit-wrapper"
  >
    <!-- form -->
    <b-form id="warehousechargeForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="店铺ID"
            label-for="warehouse_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="warehouse_id"
              size="sm"
              readonly=""
              v-model="this.warehousecharge.warehouse_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="责任人ID"
                  label-for="user_id"
                  label-size="sm"
                  class="mb-1 required"
          >
            <b-form-input
                    id="duty_name"
                    size="sm"
                    v-model="warehousecharge.user_id"
                    @click="showPeopleModal()"
                    placeholder="请选择责任人"
                    readonly
            />
          </b-form-group>
        </b-col>

                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
    <b-modal
            id="modal-single"
            ok-only
            ok-title="确认"
            @ok="onSelectPeople"
            cancel-title="取消"
            centered
            size="lg"
            :title="'请选择'"
            ref="peopleModal"
    >
      <user-select
              ref="peopleSelect" v-on:table="fromChildren($event,'user')"
      >
      </user-select>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import warehousechargeStore from './warehousechargeStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    UserSelect,
  },
  data() {
    return {
      id: ref(0),
      warehousecharge: ref({}),
      userDepartment:'',
    }
  },
  methods:{
    showPeopleModal(){
      this.$refs['peopleModal'].show()
    },
    onSelectPeople() {
      let returnData = {}
      if(this.$refs.peopleSelect.getSelected().length > 0){
        returnData = this.$refs.peopleSelect.getSelected()[0]
        this.warehousecharge.user_id = returnData.user_id
        //this.allotoutbound.duty_name = returnData.full_name
        this.$forceUpdate()
      }
    },
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('warehousecharge')) store.registerModule('warehousecharge', warehousechargeStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('warehousecharge')) store.unregisterModule('warehousecharge')
    })

    const edit = function() {
      store.dispatch('warehousecharge/edit', {id: this.id}).then(res => {
        this.warehousecharge = res.data.data
      })
    }

    const view = function() {
      store.dispatch('warehousecharge/view', {id: this.id}).then(res => {
        this.warehousecharge = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      if(this.warehousecharge.user_id==null||this.warehousecharge.user_id==""){
        toast.error("请先选择责任人")
        return
      }else{
        store.dispatch('warehousecharge/save', this.warehousecharge).then(res => {
          toast.success('数据已保存!')
          this.$router.push({ name: 'apps-warehousecharge-list',query:{id:this.warehousecharge.warehouse_id}});
        })
      }
    }

    const fromChildren = function (data,modal) {
      if (modal=='user'){
        this.warehousecharge.user_id = data.user_id
        //this.warehousecharge.duty_name = data.full_name
        this.$forceUpdate()
      }
      this.$bvModal.hide('modal-single')
    }

    return {
      edit,
      view,
      cancel,
      save,
      fromChildren,


      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit();
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
